/* global config, moment, Promise */

'use strict';

angular.module('managerApp').controller('HelpController', (
        $scope, $mdDialog, toast, help) => {

    $scope.config = config;

    $scope.onFileSelect = (index, files) => {
        let item = $scope.items[index], file = files[0];
        if (!file) {
            return;
        }
        $scope.$apply(function () {
            item.uploadable = true;
            item.file = file;
        });
    };
    $scope.uploadFile = (index) => {
        let item = $scope.items[index];
        let formData = new FormData();
        formData.append(item.key, item.file);
        help.upload(formData).then((res) => {
            if (res) {
                toast.show('Le slide a bien été ajouté.', 'success');
            }
        }).catch((err) => {
            toast.show(err, 'error', true);
        });
    };

    function getName(key) {
        switch (key) {
            case 'faq':
                return 'Foire aux questions';
            case 'cgv':
                return 'Conditions générales de vente';
            case 'cgu':
                return 'Conditions générales d\'utilisation';
            case 'legal':
                return 'Mentions légales';
            case 'privacy':
                return 'Règles de confidentialité';
            default:
                return '';
        }
    }
    function getSliders() {
        help.get().then((data) => {
            $scope.items = data;
            for (let i = 0; i < $scope.items.length; i++) {
                let item = $scope.items[i];
                item.name = getName(item.key);
            }
            $scope.$apply();
        }).catch((err) => {
            toast(err, 'error');
        });
    }

    getSliders();

});
